import React, { useEffect, useState } from "react";
import "./App.css";
import BBB from "./Assests/BBBlogo_updated.png";

function App() {
  const [state, setState] = useState("loginPage");

  //To apply styles for the Form field coming from the wirewheel instance

  useEffect(() => {
    const closeSetIntervals = () => {
      clearInterval(closeSetInterval);
    };

    //Code to set the Form extracted form the script tag to the Top
    const closeSetInterval = setInterval(() => {
      // console.log(document.getElementsByClassName("ww-page-wrapper")[0]);
      if (
        document.getElementsByClassName("ww-page-wrapper")[0] ||
        document.getElementsByTagName("main")[0]
      ) {
        document.getElementsByClassName("ww-page-wrapper")[0].id =
          "custom-styles";

        //Submit button
        if (state === "loginPage") {
          console.log(
            document.getElementsByClassName("ww-self-service__form-submit")[0]
          );
          document
            .getElementsByClassName("ww-self-service__form-submit")[0]
            .addEventListener("click", () => {
              console.log("Submit clicked");
              setState("OpenPage");
            });
        }
      }
      //Code to close the set interval for the form
      if (document.getElementsByClassName("ww-page-wrapper")[0]) {
        // setState(true);
        closeSetIntervals();
      }
    }, 1000);

    //Code to point the open  request button to wirewheel instance

    if (state === "OpenPage") {
      var openRequestClose = setInterval(() => {
        console.log("Open Request");
        if (
          document.getElementsByClassName("ww-self-service__return-button")[0]
        ) {
          console.log(
            document.getElementsByClassName("ww-self-service__return-button")[0]
              .href
          );
          let str = document.getElementsByClassName(
            "ww-self-service__return-button"
          )[0].href;
          let arr = str.split("/");
          console.log(arr[5]);
          document.getElementsByClassName(
            "ww-self-service__return-button"
          )[0].href = `https://bbbeups-dev.wirewheel.io/tasking/task-response/${arr[5]}`;
          setState("opened");
          if (state === "opened") {
            clearInterval(openRequestClose);
          }
        }
      }, 1000);
    }

    // clean Up

    return () => {
      // console.log("Destroyed");
      // clearInterval(openRequestClose);
      clearInterval(closeSetInterval);
    };
  });

  return (
    <div className="App">
      <div className="App-container">
        <img src={BBB} alt="BBB logo" className="BBB-logo"></img>
        {/*  <div className="App-container-Title">
          <h1>National </h1>
          <h1>Programs</h1>
        </div> */}
      </div>
      <div id="stateChange" style={{ color: "white", display: "none" }}>
        {state}
      </div>
    </div>
  );
}

export default App;
